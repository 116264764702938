#main-footer {
  background-color: #91c0b6;
  position: relative;
  width: 100vw;
  padding: 65px 0 25px;
  overflow: hidden;

  @media (max-width: 1023px) {
    padding: 80px 0 140px;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    &:nth-child(2) {
      align-items: center;
      margin-top: 85px;

      @media (max-width: 1023px) {
        margin: 40px 0 0;
      }
    }

    .logo {
      width: 630px;

      @media (max-width: 1023px) {
        width: auto;
        margin-bottom: 40px;
      }

      a {
        position: relative;
        display: inline-block;

        svg {
          display: block;

          &:nth-child(1) {
            position: relative;
            overflow: visible;
            transform: rotate(-90deg);
            z-index: 1;

            circle {
              stroke-width: 2px;
              stroke-dasharray: 420;
              stroke-dashoffset: 420;
              transition-property: stroke-dashoffset;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }
          }

          &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            z-index: 2;
          }
        }
      }

      &.appear a svg {
        &:nth-child(1) circle {
          stroke-dashoffset: 0;
        }

        &:nth-child(2) {
          opacity: 1;
          transition-delay: 0.1s;
        }
      }
    }

    .left {
      display: flex;
      align-items: center;
      width: 630px;

      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      &-copyright {
        color: #101011;
        font-size: 12px;
        font-weight: normal;
      }

      &-navigation {
        margin-left: 10px;

        @media (max-width: 1023px) {
          margin: 40px 0 0;
        }

        ul {
          display: flex;
          align-items: center;

          @media (max-width: 1023px) {
            flex-direction: column;
          }

          li {
            &:not(:last-child) {
              margin-right: 10px;

              @media (max-width: 1023px) {
                margin: 0 0 10px;
              }
            }

            a {
              color: #101011;
              font-size: 12px;
              font-weight: normal;
              text-transform: uppercase;
              display: inline-block;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &:hover {
                color: #f2efe8;
              }
            }
          }
        }
      }
    }

    .right {
      &-navigation {
        margin-bottom: 100px;

        @media (max-width: 1023px) {
          margin-bottom: 40px;
        }

        ul {
          @media (max-width: 1023px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }

          li {
            display: block;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            a {
              color: #101011;
              font-size: 12px;
              font-weight: normal;
              text-transform: uppercase;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &:hover {
                color: #f2efe8;
              }
            }
          }
        }
      }

      &-social {
        span {
          color: #101011;
          font-size: 12px;
          font-weight: normal;
          text-transform: uppercase;
          display: block;
          margin-bottom: 20px;

          @media (max-width: 1023px) {
            text-align: center;
          }
        }

        ul {
          display: flex;
          align-items: center;

          li {
            &:not(:last-child) {
              margin-right: 20px;
            }

            a {
              display: inline-block;

              svg {
                display: block;

                path {
                  fill: #101011;
                  transition-property: fill;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);
                }
              }

              &:hover svg path {
                fill: #f2efe8;
              }
            }
          }
        }
      }

      &-signature {
        @media (max-width: 1023px) {
          margin-top: 40px;
        }

        a {
          display: flex;
          align-items: center;

          span {
            color: #101011;
            font-size: 12px;
            font-weight: normal;
            text-transform: uppercase;
            display: inline-block;
            margin-right: 10px;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          svg path {
            transition-property: fill;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &:hover {
            span {
              color: #f2efe8;
            }

            svg path {
              fill: #f2efe8;
            }
          }
        }
      }
    }

    .scroll-top {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 220px;

      @media (max-width: 1023px) {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }

      &--button {
        @extend %cover;

        cursor: pointer;
        z-index: 3;
      }

      &--icon {
        position: relative;

        .icon {
          svg {
            display: block;
          }

          &:nth-child(1) {
            position: relative;
            transition-property: transform;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
            z-index: 1;

            svg {
              transform: rotate(-90deg);

              ellipse {
                stroke-dasharray: 138;
                stroke-dashoffset: 138;
                transition-property: stroke-dashoffset;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }
            }
          }

          &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: 0;
            transform: translate(-50%, calc(-50% + 5px));
            transition-property: opacity, transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            z-index: 2;

            svg {
              transition-property: transform;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);
            }
          }
        }
      }

      &--title {
        color: black;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        margin-top: 20px;
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      &.appear {
        .scroll-top--icon {
          .icon {
            &:nth-child(1) svg ellipse {
              stroke-dashoffset: 0;
              transition-delay: 0.5s;
            }

            &:nth-child(2) {
              opacity: 1;
              transform: translate(-50%, -50%);
              transition-delay: 0.65s;
            }
          }
        }

        .scroll-top--title {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0.75s;
        }

        &:hover {
          .scroll-top--icon {
            .icon {
              &:nth-child(1) {
                transform: scale(1.15);
              }

              &:nth-child(2) svg {
                transform: translateY(-2px);
              }
            }
          }
        }
      }
    }
  }
}
