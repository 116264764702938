%cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

%gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
