#main-preloader {
  @extend %cover;

  background-color: #91c0b6;
  position: fixed;
  pointer-events: initial;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  overflow: hidden;
  z-index: 60;

  &::before {
    @extend %cover;

    content: '';
    background-color: #f2efe8;
    transform-origin: calc(50% - 26px) 50%;
    transform: scaleX(0);
    transition-property: transform;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);
  }

  &[data-theme='default'] {
    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        transition-property: transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        path {
          fill: black;
        }
      }

      &:nth-child(1) {
        clip-path: polygon(0 0, 50px 0, 50px 100%, 0 100%);

        svg {
          transform: translateX(55px);
        }
      }

      &:nth-child(2) {
        clip-path: polygon(53px 0, 100% 0, 100% 100%, 53px 100%);

        svg {
          transform: translateX(-110px);
        }
      }
    }

    &.animate {
      &::before {
        transform: scaleX(1);
        transition-delay: 1.1s;
      }

      .logo {
        svg {
          transform: translateX(0);
        }

        &:nth-child(2) svg {
          transition-delay: 0.7s;
        }
      }
    }

    &.remove {
      opacity: 0;
      pointer-events: none;
    }
  }

  &[data-theme='fast'] {
    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        display: block;

        &:nth-child(1) {
          position: relative;
          overflow: visible;
          transform: rotate(-90deg);
          z-index: 1;

          circle {
            stroke: black;
            stroke-width: 2px;
            stroke-dasharray: 420;
            stroke-dashoffset: 0;
          }
        }

        &:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;

          path {
            fill: black;
          }
        }
      }
    }

    &.animate .logo svg {
      &:nth-child(1) circle {
        transition-property: stroke-dashoffset;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        stroke-dashoffset: -420;
      }

      &:nth-child(2) {
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        transition-delay: 0.2s;
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.2);
      }
    }

    &.remove {
      opacity: 0;
      pointer-events: none;
    }
  }
}

#main-transition {
  @extend %cover;

  background-color: #91c0b6;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 65;

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      display: block;

      &:nth-child(1) {
        position: relative;
        overflow: visible;
        transform: rotate(-90deg);
        z-index: 1;

        circle {
          stroke: black;
          stroke-width: 2px;
          stroke-dasharray: 420;
          stroke-dashoffset: 420;
          transition-property: stroke-dashoffset;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);
        }
      }

      &:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.2);
        z-index: 2;

        path {
          fill: black;
        }
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: initial;

    .logo svg {
      &:nth-child(1) circle {
        stroke-dashoffset: 0;
        transition-delay: 0.3s;
      }

      &:nth-child(2) {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        transition-delay: 0.5s;
      }
    }
  }
}
