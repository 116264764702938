.appear-fade,
.appear-fade-horizontal,
.appear-fade-vertical {
  will-change: opacity, transform;
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: time(fast);
  transition-timing-function: ease(inout);

  &.appear {
    transition-duration: 2.5s;
    opacity: 1;
    transform: translate(0, 0) rotate(0deg) skew(0deg);
  }
}

.appear-fade-vertical {
  transform: translate(0, 5px);
}

.appear-fade-horizontal {
  transform: translate(5px, 0);
}
