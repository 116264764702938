#main-menu {
  @extend %cover;

  background-color: #193330;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  overflow: hidden;
  z-index: 55;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 36px 0 0;
    z-index: 2;

    @media (max-width: 1023px) {
      .container {
        display: flex;
        justify-content: flex-end;
      }
    }

    .close {
      svg path {
        transition-property: fill;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      &:hover svg path {
        fill: #34fd71;
      }
    }
  }

  .flex-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding-left: 260px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 0 60px;
    }

    .content {
      display: flex;
      align-items: flex-start;

      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .logo {
      position: relative;
      margin-right: 130px;

      @media (max-width: 1023px) {
        margin: 0 0 40px;
      }

      a {
        position: relative;
        display: inline-block;

        svg {
          display: block;

          &:nth-child(1) {
            position: relative;
            overflow: visible;
            transform: rotate(-90deg);
            z-index: 1;

            circle {
              stroke: white;
              stroke-width: 2px;
              stroke-dasharray: 420;
              stroke-dashoffset: 420;
              transition-property: stroke-dashoffset;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }
          }

          &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            z-index: 2;

            path {
              fill: white;
            }
          }
        }
      }
    }

    .navigation {
      padding-top: 50px;

      @media (max-width: 1023px) {
        padding: 0;
        text-align: center;
      }

      ul {
        li {
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:not(:last-child) {
            margin-bottom: 40px;

            @media (max-width: 1023px) {
              margin-bottom: 30px;
            }

            &.small {
              margin-bottom: 15px;
            }
          }

          &.small {
            a {
              font-size: 16px;
            }
          }

          a {
            color: white;
            font-family: font(title);
            font-size: 20px;
            font-variation-settings: 'wght' 500;
            font-weight: 500;
            text-transform: uppercase;
            transition-property: color, font-variation-settings;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            @media (min-width: 1024px) {
              &:hover {
                color: #34fd71;
                font-variation-settings: 'wght' 700;
              }
            }
          }
        }
      }
    }

    .social {
      position: absolute;
      bottom: 60px;
      left: 260px;

      @media (max-width: 1023px) {
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
      }

      ul {
        display: flex;
        align-items: center;

        li {
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:not(:last-child) {
            margin-right: 18px;
          }

          a {
            display: inline-block;

            svg path {
              fill: white;
              transition-property: fill;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:hover svg path {
              fill: #34fd71;
            }
          }
        }
      }
    }
  }

  &.sticky .header {
    padding: 22px 0 0;
  }

  &.open {
    opacity: 1;
    pointer-events: initial;

    .flex-wrapper {
      .logo a svg {
        &:nth-child(1) circle {
          stroke-dashoffset: 0;
          transition-delay: 0.3s;
        }

        &:nth-child(2) {
          opacity: 1;
          transition-delay: 0.4s;
        }
      }

      .navigation ul li {
        opacity: 1;
        transform: translateX(0);

        @for $i from 0 to 7 {
          &:nth-child(#{$i}) {
            transition-delay: 0.1s * $i + 0.4s;
          }
        }
      }

      .social ul li {
        opacity: 1;
        transform: translateX(0);

        @for $i from 0 to 7 {
          &:nth-child(#{$i}) {
            transition-delay: 0.1s * $i + 0.8s;
          }
        }
      }
    }
  }
}
