@font-face {
  font-family: 'PP Neue Machina';
  src: url('../../assets/fonts/PPNeueMachina-Variable.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'PP Neue Machina';
//   src: url('../../assets/fonts/PPNeueMachina-Light.woff2') format('woff2'),
//     url('../../assets/fonts/PPNeueMachina-Light.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'PP Neue Machina';
//   src: url('../../assets/fonts/PPNeueMachina-Regular.woff2') format('woff2'),
//     url('../../assets/fonts/PPNeueMachina-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'PP Neue Machina';
//   src: url('../../assets/fonts/PPNeueMachina-Medium.woff2') format('woff2'),
//     url('../../assets/fonts/PPNeueMachina-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'PP Neue Machina';
//   src: url('../../assets/fonts/PPNeueMachina-Bold.woff2') format('woff2'),
//     url('../../assets/fonts/PPNeueMachina-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'PP Neue Machina';
//   src: url('../../assets/fonts/PPNeueMachina-Ultrabold.woff2') format('woff2'),
//     url('../../assets/fonts/PPNeueMachina-Ultrabold.woff') format('woff');
//   font-weight: 800;
//   font-style: normal;
//   font-display: swap;
// }
