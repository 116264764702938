/**
* Vars.
*/
#main-header::before, #main-header .flex-wrapper .left-menu button span::before, #main-header .flex-wrapper .left-logo a, #main-footer .flex-wrapper .scroll-top--button, #main-menu, #main-preloader, #main-preloader::before, #main-transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

/**
* Base.
*/
@font-face {
  font-family: 'PP Neue Machina';
  src: url("../../assets/fonts/PPNeueMachina-Variable.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

html,
body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; }

body {
  font-family: Helvetica, Helvetica Neue, Arial, Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 195;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms; }
  body.shadow {
    width: 100%;
    overflow: hidden; }
    body.shadow::before {
      opacity: 0.6;
      pointer-events: initial; }
  body.modal-open, body.menu-open {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden; }

.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto; }
  @media (max-width: 1260px) {
    .container {
      padding: 0 25px; } }

* {
  font-family: Helvetica, Helvetica Neue, Arial, Roboto, sans-serif;
  line-height: 100%;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style-type: none; }

span.break-line {
  width: 100%;
  display: block; }

s {
  text-decoration: none;
  display: inline-block;
  transition-property: transform;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }

form {
  font-family: Helvetica, Helvetica Neue, Arial, Roboto, sans-serif; }
  form input {
    line-height: initial; }
  form input,
  form textarea,
  form select {
    outline: none !important;
    border-radius: 0px; }
    form input:active, form input:focus,
    form textarea:active,
    form textarea:focus,
    form select:active,
    form select:focus {
      outline: none !important; }

a,
a:link,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
  outline: none !important; }

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer; }

button,
button:active,
button:focus {
  outline: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  margin: 0; }

ul,
ol {
  margin: 0;
  list-style-type: none; }

label {
  font-weight: normal; }

/** Transition slide. */
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-in {
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: 1000ms; }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fade-out;
  animation-fill-mode: forwards;
  animation-duration: 1000ms; }

[rel='lazy_load'] {
  overflow: hidden; }

@keyframes lazy_load {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 50%; }
  50% {
    transform: scaleX(1);
    transform-origin: 0% 50%; }
  50.1% {
    transform: scaleX(1);
    transform-origin: 100% 50%; }
  100% {
    transform: scaleX(0);
    transform-origin: 100% 50%; } }
  [rel='lazy_load']:not(.no-animate) img {
    z-index: 1;
    transform-origin: 25% 25%;
    opacity: 0;
    transform: scale(1.08);
    transition-property: opacity, transform;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  [rel='lazy_load'].no-animate img {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;
    transition-timing-function: ease; }
  [rel='lazy_load'].loaded:not(.no-animate) img {
    opacity: 1;
    transform: scale(1); }
  [rel='lazy_load'].loaded.no-animate img {
    opacity: 1; }

.screen-progress {
  background-color: #252029;
  color: white;
  text-align: center;
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  margin-top: 15px;
  padding: 40px 20px 32px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: margin-top 0.7s cubic-bezier(0.5, 0, 0, 1), opacity 0.7s cubic-bezier(0, 0, 0.3, 1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  .screen-progress.open {
    margin-top: 0;
    opacity: 1; }
  .screen-progress .screen-message {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 15px; }
  .screen-progress .spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative; }

@keyframes progress_spinner {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    opacity: 0.3; }
  100% {
    transform: scale(1);
    opacity: 0; } }
    .screen-progress .spinner span {
      background-color: white;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
      animation: progress_spinner 1.5s infinite; }
      .screen-progress .spinner span:nth-child(2) {
        animation-delay: 0.5s; }

.appear-fade,
.appear-fade-horizontal,
.appear-fade-vertical {
  will-change: opacity, transform;
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  .appear-fade.appear,
  .appear-fade-horizontal.appear,
  .appear-fade-vertical.appear {
    transition-duration: 2.5s;
    opacity: 1;
    transform: translate(0, 0) rotate(0deg) skew(0deg); }

.appear-fade-vertical {
  transform: translate(0, 5px); }

.appear-fade-horizontal {
  transform: translate(5px, 0); }

/**
* Globals.
*/
#main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 50;
  transition-property: background-color;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  overflow: hidden; }
  #main-header::before {
    content: '';
    background-color: black;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    transition-property: transform;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    z-index: 1; }
  #main-header .flex-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    transition-property: padding;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    z-index: 2; }
    #main-header .flex-wrapper .left {
      display: flex;
      align-items: center; }
      @media (max-width: 1023px) {
        #main-header .flex-wrapper .left {
          width: 100%;
          justify-content: space-between; } }
      #main-header .flex-wrapper .left svg line {
        stroke: black; }
      #main-header .flex-wrapper .left-menu {
        display: flex;
        margin-right: 235px;
        opacity: 0;
        transform: translateX(10px);
        transition-property: opacity, transform;
        transition-duration: 1.5s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        @media (max-width: 1023px) {
          #main-header .flex-wrapper .left-menu {
            order: 2;
            margin: 0; } }
        #main-header .flex-wrapper .left-menu button {
          position: relative;
          width: 26px;
          height: 17px; }
          #main-header .flex-wrapper .left-menu button span {
            position: absolute;
            left: 0;
            right: 0;
            height: 1px; }
            #main-header .flex-wrapper .left-menu button span::before {
              content: '';
              background-color: white;
              transition-property: background-color, width;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
              z-index: 1; }
            #main-header .flex-wrapper .left-menu button span:nth-child(1) {
              top: 0; }
            #main-header .flex-wrapper .left-menu button span:nth-child(2) {
              top: 8px; }
            #main-header .flex-wrapper .left-menu button span:nth-child(3) {
              bottom: 0; }
              #main-header .flex-wrapper .left-menu button span:nth-child(3)::before {
                width: 13px; }
          #main-header .flex-wrapper .left-menu button:hover span::before {
            background-color: #34fd71; }
          #main-header .flex-wrapper .left-menu button:hover span:nth-child(1)::before {
            width: 33%; }
          #main-header .flex-wrapper .left-menu button:hover span:nth-child(2)::before {
            width: 66%; }
          #main-header .flex-wrapper .left-menu button:hover span:nth-child(3)::before {
            width: 100%; }
      #main-header .flex-wrapper .left-logo {
        position: relative;
        width: 153px;
        height: 34px; }
        @media (max-width: 1023px) {
          #main-header .flex-wrapper .left-logo {
            order: 1; } }
        #main-header .flex-wrapper .left-logo a {
          z-index: 3; }
        #main-header .flex-wrapper .left-logo .logo {
          position: absolute;
          top: 0;
          left: 0; }
          #main-header .flex-wrapper .left-logo .logo svg {
            transition-property: transform;
            transition-duration: 1.5s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-header .flex-wrapper .left-logo .logo svg path {
              transition-property: fill;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-header .flex-wrapper .left-logo .logo:nth-child(1) {
            clip-path: polygon(0 0, 50px 0, 50px 100%, 0 100%); }
            #main-header .flex-wrapper .left-logo .logo:nth-child(1) svg {
              transform: translateX(55px); }
          #main-header .flex-wrapper .left-logo .logo:nth-child(2) {
            clip-path: polygon(53px 0, 100% 0, 100% 100%, 53px 100%); }
            #main-header .flex-wrapper .left-logo .logo:nth-child(2) svg {
              transform: translateX(-110px); }
        #main-header .flex-wrapper .left-logo:hover .logo svg path {
          fill: #34fd71; }
    #main-header .flex-wrapper .right {
      opacity: 0;
      transform: translateX(10px);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      @media (max-width: 1023px) {
        #main-header .flex-wrapper .right {
          display: none; } }
      #main-header .flex-wrapper .right-account {
        display: flex;
        align-items: center; }
        #main-header .flex-wrapper .right-account a {
          color: white;
          font-size: 12px;
          font-weight: normal;
          transition-property: color;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-header .flex-wrapper .right-account a:not(:last-child) {
            margin-right: 32px; }
          #main-header .flex-wrapper .right-account a:hover {
            color: #34fd71; }
    #main-header .flex-wrapper.appear .left-menu {
      opacity: 1;
      transform: translateX(0); }
    #main-header .flex-wrapper.appear .left-logo .logo svg {
      transform: translateX(0); }
    #main-header .flex-wrapper.appear .left-logo .logo:nth-child(1) svg {
      transition-delay: 0.15s; }
    #main-header .flex-wrapper.appear .left-logo .logo:nth-child(2) svg {
      transition-delay: 0.45s; }
    #main-header .flex-wrapper.appear .right {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.6s; }
  #main-header[data-theme='black'] .flex-wrapper .left-menu button span::before {
    background-color: black; }
  #main-header[data-theme='black'] .flex-wrapper .left-menu button:hover span::before {
    background-color: rgba(0, 0, 0, 0.4); }
  #main-header[data-theme='black'] .flex-wrapper .left-logo .logo svg path {
    fill: black; }
  #main-header[data-theme='black'] .flex-wrapper .left-logo:hover .logo svg path {
    fill: rgba(0, 0, 0, 0.2); }
  #main-header[data-theme='black'] .flex-wrapper .right-account a {
    color: black; }
    #main-header[data-theme='black'] .flex-wrapper .right-account a:hover {
      color: rgba(0, 0, 0, 0.2); }
  #main-header.sticky::before {
    transform: scaleY(1); }
  #main-header.sticky .flex-wrapper {
    padding: 15px 0; }
    #main-header.sticky .flex-wrapper .left-menu button span::before {
      background-color: white; }
    #main-header.sticky .flex-wrapper .left-menu button:hover span::before {
      background-color: #34fd71; }
    #main-header.sticky .flex-wrapper .left-logo .logo svg path {
      fill: white; }
    #main-header.sticky .flex-wrapper .left-logo:hover .logo svg path {
      fill: #34fd71; }
    #main-header.sticky .flex-wrapper .right-account a {
      color: white; }
      #main-header.sticky .flex-wrapper .right-account a:hover {
        color: #34fd71; }

#main-footer {
  background-color: #91c0b6;
  position: relative;
  width: 100vw;
  padding: 65px 0 25px;
  overflow: hidden; }
  @media (max-width: 1023px) {
    #main-footer {
      padding: 80px 0 140px; } }
  #main-footer .flex-wrapper {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 1023px) {
      #main-footer .flex-wrapper {
        flex-direction: column;
        align-items: center; } }
    #main-footer .flex-wrapper:nth-child(2) {
      align-items: center;
      margin-top: 85px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper:nth-child(2) {
          margin: 40px 0 0; } }
    #main-footer .flex-wrapper .logo {
      width: 630px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .logo {
          width: auto;
          margin-bottom: 40px; } }
      #main-footer .flex-wrapper .logo a {
        position: relative;
        display: inline-block; }
        #main-footer .flex-wrapper .logo a svg {
          display: block; }
          #main-footer .flex-wrapper .logo a svg:nth-child(1) {
            position: relative;
            overflow: visible;
            transform: rotate(-90deg);
            z-index: 1; }
            #main-footer .flex-wrapper .logo a svg:nth-child(1) circle {
              stroke-width: 2px;
              stroke-dasharray: 420;
              stroke-dashoffset: 420;
              transition-property: stroke-dashoffset;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-footer .flex-wrapper .logo a svg:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition-property: opacity;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
            z-index: 2; }
      #main-footer .flex-wrapper .logo.appear a svg:nth-child(1) circle {
        stroke-dashoffset: 0; }
      #main-footer .flex-wrapper .logo.appear a svg:nth-child(2) {
        opacity: 1;
        transition-delay: 0.1s; }
    #main-footer .flex-wrapper .left {
      display: flex;
      align-items: center;
      width: 630px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .left {
          flex-direction: column;
          align-items: center;
          width: 100%; } }
      #main-footer .flex-wrapper .left-copyright {
        color: #101011;
        font-size: 12px;
        font-weight: normal; }
      #main-footer .flex-wrapper .left-navigation {
        margin-left: 10px; }
        @media (max-width: 1023px) {
          #main-footer .flex-wrapper .left-navigation {
            margin: 40px 0 0; } }
        #main-footer .flex-wrapper .left-navigation ul {
          display: flex;
          align-items: center; }
          @media (max-width: 1023px) {
            #main-footer .flex-wrapper .left-navigation ul {
              flex-direction: column; } }
          #main-footer .flex-wrapper .left-navigation ul li:not(:last-child) {
            margin-right: 10px; }
            @media (max-width: 1023px) {
              #main-footer .flex-wrapper .left-navigation ul li:not(:last-child) {
                margin: 0 0 10px; } }
          #main-footer .flex-wrapper .left-navigation ul li a {
            color: #101011;
            font-size: 12px;
            font-weight: normal;
            text-transform: uppercase;
            display: inline-block;
            transition-property: color;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-footer .flex-wrapper .left-navigation ul li a:hover {
              color: #f2efe8; }
    #main-footer .flex-wrapper .right-navigation {
      margin-bottom: 100px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .right-navigation {
          margin-bottom: 40px; } }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .right-navigation ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center; } }
      #main-footer .flex-wrapper .right-navigation ul li {
        display: block; }
        #main-footer .flex-wrapper .right-navigation ul li:not(:last-child) {
          margin-bottom: 10px; }
        #main-footer .flex-wrapper .right-navigation ul li a {
          color: #101011;
          font-size: 12px;
          font-weight: normal;
          text-transform: uppercase;
          transition-property: color;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-footer .flex-wrapper .right-navigation ul li a:hover {
            color: #f2efe8; }
    #main-footer .flex-wrapper .right-social span {
      color: #101011;
      font-size: 12px;
      font-weight: normal;
      text-transform: uppercase;
      display: block;
      margin-bottom: 20px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .right-social span {
          text-align: center; } }
    #main-footer .flex-wrapper .right-social ul {
      display: flex;
      align-items: center; }
      #main-footer .flex-wrapper .right-social ul li:not(:last-child) {
        margin-right: 20px; }
      #main-footer .flex-wrapper .right-social ul li a {
        display: inline-block; }
        #main-footer .flex-wrapper .right-social ul li a svg {
          display: block; }
          #main-footer .flex-wrapper .right-social ul li a svg path {
            fill: #101011;
            transition-property: fill;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-footer .flex-wrapper .right-social ul li a:hover svg path {
          fill: #f2efe8; }
    @media (max-width: 1023px) {
      #main-footer .flex-wrapper .right-signature {
        margin-top: 40px; } }
    #main-footer .flex-wrapper .right-signature a {
      display: flex;
      align-items: center; }
      #main-footer .flex-wrapper .right-signature a span {
        color: #101011;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 10px;
        transition-property: color;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-footer .flex-wrapper .right-signature a svg path {
        transition-property: fill;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-footer .flex-wrapper .right-signature a:hover span {
        color: #f2efe8; }
      #main-footer .flex-wrapper .right-signature a:hover svg path {
        fill: #f2efe8; }
    #main-footer .flex-wrapper .scroll-top {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 220px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .scroll-top {
          position: absolute;
          bottom: 25px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0; } }
      #main-footer .flex-wrapper .scroll-top--button {
        cursor: pointer;
        z-index: 3; }
      #main-footer .flex-wrapper .scroll-top--icon {
        position: relative; }
        #main-footer .flex-wrapper .scroll-top--icon .icon svg {
          display: block; }
        #main-footer .flex-wrapper .scroll-top--icon .icon:nth-child(1) {
          position: relative;
          transition-property: transform;
          transition-duration: 0.5s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          z-index: 1; }
          #main-footer .flex-wrapper .scroll-top--icon .icon:nth-child(1) svg {
            transform: rotate(-90deg); }
            #main-footer .flex-wrapper .scroll-top--icon .icon:nth-child(1) svg ellipse {
              stroke-dasharray: 138;
              stroke-dashoffset: 138;
              transition-property: stroke-dashoffset;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-footer .flex-wrapper .scroll-top--icon .icon:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, calc(-50% + 5px));
          transition-property: opacity, transform;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          z-index: 2; }
          #main-footer .flex-wrapper .scroll-top--icon .icon:nth-child(2) svg {
            transition-property: transform;
            transition-duration: 0.5s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-footer .flex-wrapper .scroll-top--title {
        color: black;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        margin-top: 20px;
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-footer .flex-wrapper .scroll-top.appear .scroll-top--icon .icon:nth-child(1) svg ellipse {
        stroke-dashoffset: 0;
        transition-delay: 0.5s; }
      #main-footer .flex-wrapper .scroll-top.appear .scroll-top--icon .icon:nth-child(2) {
        opacity: 1;
        transform: translate(-50%, -50%);
        transition-delay: 0.65s; }
      #main-footer .flex-wrapper .scroll-top.appear .scroll-top--title {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.75s; }
      #main-footer .flex-wrapper .scroll-top.appear:hover .scroll-top--icon .icon:nth-child(1) {
        transform: scale(1.15); }
      #main-footer .flex-wrapper .scroll-top.appear:hover .scroll-top--icon .icon:nth-child(2) svg {
        transform: translateY(-2px); }

#main-menu {
  background-color: #193330;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  overflow: hidden;
  z-index: 55; }
  #main-menu .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 36px 0 0;
    z-index: 2; }
    @media (max-width: 1023px) {
      #main-menu .header .container {
        display: flex;
        justify-content: flex-end; } }
    #main-menu .header .close svg path {
      transition-property: fill;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    #main-menu .header .close:hover svg path {
      fill: #34fd71; }
  #main-menu .flex-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding-left: 260px; }
    @media (max-width: 1023px) {
      #main-menu .flex-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 0 60px; } }
    #main-menu .flex-wrapper .content {
      display: flex;
      align-items: flex-start; }
      @media (max-width: 1023px) {
        #main-menu .flex-wrapper .content {
          flex-direction: column;
          align-items: center; } }
    #main-menu .flex-wrapper .logo {
      position: relative;
      margin-right: 130px; }
      @media (max-width: 1023px) {
        #main-menu .flex-wrapper .logo {
          margin: 0 0 40px; } }
      #main-menu .flex-wrapper .logo a {
        position: relative;
        display: inline-block; }
        #main-menu .flex-wrapper .logo a svg {
          display: block; }
          #main-menu .flex-wrapper .logo a svg:nth-child(1) {
            position: relative;
            overflow: visible;
            transform: rotate(-90deg);
            z-index: 1; }
            #main-menu .flex-wrapper .logo a svg:nth-child(1) circle {
              stroke: white;
              stroke-width: 2px;
              stroke-dasharray: 420;
              stroke-dashoffset: 420;
              transition-property: stroke-dashoffset;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-menu .flex-wrapper .logo a svg:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition-property: opacity;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
            z-index: 2; }
            #main-menu .flex-wrapper .logo a svg:nth-child(2) path {
              fill: white; }
    #main-menu .flex-wrapper .navigation {
      padding-top: 50px; }
      @media (max-width: 1023px) {
        #main-menu .flex-wrapper .navigation {
          padding: 0;
          text-align: center; } }
      #main-menu .flex-wrapper .navigation ul li {
        opacity: 0;
        transform: translateX(10px);
        transition-property: opacity, transform;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-menu .flex-wrapper .navigation ul li:not(:last-child) {
          margin-bottom: 40px; }
          @media (max-width: 1023px) {
            #main-menu .flex-wrapper .navigation ul li:not(:last-child) {
              margin-bottom: 30px; } }
          #main-menu .flex-wrapper .navigation ul li:not(:last-child).small {
            margin-bottom: 15px; }
        #main-menu .flex-wrapper .navigation ul li.small a {
          font-size: 16px; }
        #main-menu .flex-wrapper .navigation ul li a {
          color: white;
          font-family: "PP Neue Machina";
          font-size: 20px;
          font-variation-settings: 'wght' 500;
          font-weight: 500;
          text-transform: uppercase;
          transition-property: color, font-variation-settings;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          @media (min-width: 1024px) {
            #main-menu .flex-wrapper .navigation ul li a:hover {
              color: #34fd71;
              font-variation-settings: 'wght' 700; } }
    #main-menu .flex-wrapper .social {
      position: absolute;
      bottom: 60px;
      left: 260px; }
      @media (max-width: 1023px) {
        #main-menu .flex-wrapper .social {
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%); } }
      #main-menu .flex-wrapper .social ul {
        display: flex;
        align-items: center; }
        #main-menu .flex-wrapper .social ul li {
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-menu .flex-wrapper .social ul li:not(:last-child) {
            margin-right: 18px; }
          #main-menu .flex-wrapper .social ul li a {
            display: inline-block; }
            #main-menu .flex-wrapper .social ul li a svg path {
              fill: white;
              transition-property: fill;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-menu .flex-wrapper .social ul li a:hover svg path {
              fill: #34fd71; }
  #main-menu.sticky .header {
    padding: 22px 0 0; }
  #main-menu.open {
    opacity: 1;
    pointer-events: initial; }
    #main-menu.open .flex-wrapper .logo a svg:nth-child(1) circle {
      stroke-dashoffset: 0;
      transition-delay: 0.3s; }
    #main-menu.open .flex-wrapper .logo a svg:nth-child(2) {
      opacity: 1;
      transition-delay: 0.4s; }
    #main-menu.open .flex-wrapper .navigation ul li {
      opacity: 1;
      transform: translateX(0); }
      #main-menu.open .flex-wrapper .navigation ul li:nth-child(0) {
        transition-delay: 0.4s; }
      #main-menu.open .flex-wrapper .navigation ul li:nth-child(1) {
        transition-delay: 0.5s; }
      #main-menu.open .flex-wrapper .navigation ul li:nth-child(2) {
        transition-delay: 0.6s; }
      #main-menu.open .flex-wrapper .navigation ul li:nth-child(3) {
        transition-delay: 0.7s; }
      #main-menu.open .flex-wrapper .navigation ul li:nth-child(4) {
        transition-delay: 0.8s; }
      #main-menu.open .flex-wrapper .navigation ul li:nth-child(5) {
        transition-delay: 0.9s; }
      #main-menu.open .flex-wrapper .navigation ul li:nth-child(6) {
        transition-delay: 1s; }
    #main-menu.open .flex-wrapper .social ul li {
      opacity: 1;
      transform: translateX(0); }
      #main-menu.open .flex-wrapper .social ul li:nth-child(0) {
        transition-delay: 0.8s; }
      #main-menu.open .flex-wrapper .social ul li:nth-child(1) {
        transition-delay: 0.9s; }
      #main-menu.open .flex-wrapper .social ul li:nth-child(2) {
        transition-delay: 1s; }
      #main-menu.open .flex-wrapper .social ul li:nth-child(3) {
        transition-delay: 1.1s; }
      #main-menu.open .flex-wrapper .social ul li:nth-child(4) {
        transition-delay: 1.2s; }
      #main-menu.open .flex-wrapper .social ul li:nth-child(5) {
        transition-delay: 1.3s; }
      #main-menu.open .flex-wrapper .social ul li:nth-child(6) {
        transition-delay: 1.4s; }

#main-preloader {
  background-color: #91c0b6;
  position: fixed;
  pointer-events: initial;
  transition-property: opacity;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  overflow: hidden;
  z-index: 60; }
  #main-preloader::before {
    content: '';
    background-color: #f2efe8;
    transform-origin: calc(50% - 26px) 50%;
    transform: scaleX(0);
    transition-property: transform;
    transition-duration: 1.5s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  #main-preloader[data-theme='default'] .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    #main-preloader[data-theme='default'] .logo svg {
      transition-property: transform;
      transition-duration: 1.5s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-preloader[data-theme='default'] .logo svg path {
        fill: black; }
    #main-preloader[data-theme='default'] .logo:nth-child(1) {
      clip-path: polygon(0 0, 50px 0, 50px 100%, 0 100%); }
      #main-preloader[data-theme='default'] .logo:nth-child(1) svg {
        transform: translateX(55px); }
    #main-preloader[data-theme='default'] .logo:nth-child(2) {
      clip-path: polygon(53px 0, 100% 0, 100% 100%, 53px 100%); }
      #main-preloader[data-theme='default'] .logo:nth-child(2) svg {
        transform: translateX(-110px); }
  #main-preloader[data-theme='default'].animate::before {
    transform: scaleX(1);
    transition-delay: 1.1s; }
  #main-preloader[data-theme='default'].animate .logo svg {
    transform: translateX(0); }
  #main-preloader[data-theme='default'].animate .logo:nth-child(2) svg {
    transition-delay: 0.7s; }
  #main-preloader[data-theme='default'].remove {
    opacity: 0;
    pointer-events: none; }
  #main-preloader[data-theme='fast'] .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    #main-preloader[data-theme='fast'] .logo svg {
      display: block; }
      #main-preloader[data-theme='fast'] .logo svg:nth-child(1) {
        position: relative;
        overflow: visible;
        transform: rotate(-90deg);
        z-index: 1; }
        #main-preloader[data-theme='fast'] .logo svg:nth-child(1) circle {
          stroke: black;
          stroke-width: 2px;
          stroke-dasharray: 420;
          stroke-dashoffset: 0; }
      #main-preloader[data-theme='fast'] .logo svg:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2; }
        #main-preloader[data-theme='fast'] .logo svg:nth-child(2) path {
          fill: black; }
  #main-preloader[data-theme='fast'].animate .logo svg:nth-child(1) circle {
    transition-property: stroke-dashoffset;
    transition-duration: 1.5s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    stroke-dashoffset: -420; }
  #main-preloader[data-theme='fast'].animate .logo svg:nth-child(2) {
    transition-property: opacity, transform;
    transition-duration: 1.5s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    transition-delay: 0.2s;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.2); }
  #main-preloader[data-theme='fast'].remove {
    opacity: 0;
    pointer-events: none; }

#main-transition {
  background-color: #91c0b6;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  z-index: 65; }
  #main-transition .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    #main-transition .logo svg {
      display: block; }
      #main-transition .logo svg:nth-child(1) {
        position: relative;
        overflow: visible;
        transform: rotate(-90deg);
        z-index: 1; }
        #main-transition .logo svg:nth-child(1) circle {
          stroke: black;
          stroke-width: 2px;
          stroke-dasharray: 420;
          stroke-dashoffset: 420;
          transition-property: stroke-dashoffset;
          transition-duration: 1.5s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-transition .logo svg:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.2);
        z-index: 2; }
        #main-transition .logo svg:nth-child(2) path {
          fill: black; }
  #main-transition.active {
    opacity: 1;
    pointer-events: initial; }
    #main-transition.active .logo svg:nth-child(1) circle {
      stroke-dashoffset: 0;
      transition-delay: 0.3s; }
    #main-transition.active .logo svg:nth-child(2) {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
      transition-delay: 0.5s; }
