#main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 50;
  transition-property: background-color;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  overflow: hidden;

  &::before {
    @extend %cover;

    content: '';
    background-color: black;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    transition-property: transform;
    transition-duration: time(default);
    transition-timing-function: ease(inout);
    z-index: 1;
  }

  .flex-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    transition-property: padding;
    transition-duration: time(default);
    transition-timing-function: ease(inout);
    z-index: 2;

    .left {
      display: flex;
      align-items: center;

      @media (max-width: 1023px) {
        width: 100%;
        justify-content: space-between;
      }

      svg line {
        stroke: black;
      }

      &-menu {
        display: flex;
        margin-right: 235px;
        opacity: 0;
        transform: translateX(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          order: 2;
          margin: 0;
        }

        button {
          position: relative;
          width: 26px;
          height: 17px;

          span {
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;

            &::before {
              @extend %cover;

              content: '';
              background-color: white;
              transition-property: background-color, width;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
              z-index: 1;
            }

            &:nth-child(1) {
              top: 0;
            }

            &:nth-child(2) {
              top: 8px;
            }

            &:nth-child(3) {
              bottom: 0;

              &::before {
                width: 13px;
              }
            }
          }

          &:hover span {
            &::before {
              background-color: #34fd71;
            }

            &:nth-child(1) {
              &::before {
                width: 33%;
              }
            }

            &:nth-child(2) {
              &::before {
                width: 66%;
              }
            }

            &:nth-child(3) {
              &::before {
                width: 100%;
              }
            }
          }
        }
      }

      &-logo {
        position: relative;
        width: 153px;
        height: 34px;

        @media (max-width: 1023px) {
          order: 1;
        }

        a {
          @extend %cover;

          z-index: 3;
        }

        .logo {
          position: absolute;
          top: 0;
          left: 0;

          svg {
            transition-property: transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            path {
              transition-property: fill;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }
          }

          &:nth-child(1) {
            clip-path: polygon(0 0, 50px 0, 50px 100%, 0 100%);

            svg {
              transform: translateX(55px);
            }
          }

          &:nth-child(2) {
            clip-path: polygon(53px 0, 100% 0, 100% 100%, 53px 100%);

            svg {
              transform: translateX(-110px);
            }
          }
        }

        &:hover .logo svg path {
          fill: #34fd71;
        }
      }
    }

    .right {
      opacity: 0;
      transform: translateX(10px);
      transition-property: opacity, transform;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);

      @media (max-width: 1023px) {
        display: none;
      }

      &-account {
        display: flex;
        align-items: center;

        a {
          color: white;
          font-size: 12px;
          font-weight: normal;
          transition-property: color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:not(:last-child) {
            margin-right: 32px;
          }

          &:hover {
            color: #34fd71;
          }
        }
      }
    }

    &.appear {
      .left {
        &-menu {
          opacity: 1;
          transform: translateX(0);
        }

        &-logo .logo {
          svg {
            transform: translateX(0);
          }

          &:nth-child(1) svg {
            transition-delay: 0.15s;
          }

          &:nth-child(2) svg {
            transition-delay: 0.45s;
          }
        }
      }

      .right {
        opacity: 1;
        transform: translateX(0);
        transition-delay: 0.6s;
      }
    }
  }

  &[data-theme='black'] {
    .flex-wrapper {
      .left {
        &-menu button {
          span {
            &::before {
              background-color: black;
            }
          }

          &:hover span {
            &::before {
              background-color: fade-out(black, 0.6);
            }
          }
        }

        &-logo {
          .logo svg path {
            fill: black;
          }

          &:hover .logo svg path {
            fill: fade-out(black, 0.8);
          }
        }
      }

      .right {
        &-account a {
          color: black;

          &:hover {
            color: fade-out(black, 0.8);
          }
        }
      }
    }
  }

  &.sticky {
    &::before {
      transform: scaleY(1);
    }

    .flex-wrapper {
      padding: 15px 0;

      .left {
        &-menu button {
          span {
            &::before {
              background-color: white;
            }
          }

          &:hover span {
            &::before {
              background-color: #34fd71;
            }
          }
        }

        &-logo {
          .logo svg path {
            fill: white;
          }

          &:hover .logo svg path {
            fill: #34fd71;
          }
        }
      }

      .right {
        &-account a {
          color: white;

          &:hover {
            color: #34fd71;
          }
        }
      }
    }
  }
}
